/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-07-01",
    versionChannel: "nightly",
    buildDate: "2023-07-01T00:25:59.437Z",
    commitHash: "29a5c6a4cd9918d5e1eb89cc42ccd03d4e920931",
};
